.gnav * {
  box-sizing: border-box !important;
}
.gnav {
  /*position: fixed;*/
  /*left: 0;*/
  /*top: 0;*/
  /*z-index: 1000;*/
  font-size: large;
  line-height: 1.5em;
  width: 100%;
  min-width: 768px;
  font-family: 'OneNikeCurrency', 'TradeGothicW01-BoldCn20 675334', Helvetica, Arial, sans-serif;
}
.gnav-bar {
  display: table;
  width: 100%;
  padding: 0 31px;
  border-bottom: 1px solid #1a1a1a;
  min-width: 1200px;
  background: #1a1a1a;
}
