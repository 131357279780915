@font-face {
  font-family: 'Nike TG';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/nikeTG.eot');
  src: url('assets/fonts/nikeTG.eot?#iefix') format('embedded-opentype'), url('assets/fonts/nikeTG.woff') format('woff'), url('assets/fonts/nikeTG.ttf') format('truetype'), url('assets/fonts/nikeTG.svg') format('svg');
}
@font-face {
  font-family: 'OneNikeGlyphs';
  src: url('assets/fonts/OneNikeGlyphs.eot');
  src: url('assets/fonts/OneNikeGlyphs.eot?#iefix') format('eot'), url('assets/fonts/OneNikeGlyphs.woff') format('woff'), url('assets/fonts/OneNikeGlyphs.ttf') format('truetype'), url('assets/fonts/OneNikeGlyphs.svg') format('svg');
}
@font-face {
  font-family: 'OneNikeCurrency';
  src: url('assets/fonts/OneNikeCurrency.eot');
  src: url('assets/fonts/OneNikeCurrency.eot?#iefix') format('eot'), url('assets/fonts/OneNikeCurrency.woff') format('woff'), url('assets/fonts/OneNikeCurrency.ttf') format('truetype'), url('assets/fonts/OneNikeCurrency.svg') format('svg');
}
@font-face {
  font-family: 'TradeGothicW01-BoldCn20 675334';
  src: url('assets/fonts/TradeGothicW01.eot');
  src: url('assets/fonts/TradeGothicW01.eot?#iefix') format('eot'), url('assets/fonts/TradeGothicW01.woff') format('woff'), url('assets/fonts/TradeGothicW01.ttf') format('truetype'), url('assets/fonts/TradeGothicW01.svg') format('svg');
}
.nsg-font-family--base {
  font-family: 'OneNikeCurrency', 'TradeGothicW01-BoldCn20 675334', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}
.nsg-glyph--logo:before,
.nsg-glyph--swoosh:before {
  content: '\f139';
}
.nsg-font-family--platform {
  font-style: normal;
}
.nsg-font-family--platform,
.nsg-font-family--platform-i {
  font-weight: 400;
  font-family: 'OneNikeCurrency', 'TradeGothicW01-BoldCn20 675334', Helvetica, Arial, sans-serif;
}
[class*='ncss-col-'] {
  line-height: inherit;
}
[class*='nsg-glyph--'] {
  display: inline-block;
  overflow: hidden;
  text-align: left;
  text-indent: 100%;
  white-space: nowrap;
  position: relative;
  vertical-align: middle;
  width: 1em;
  height: 1em;
}
[class*='nsg-glyph--']:before {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: OneNikeGlyphs;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  text-indent: 0;
  line-height: 1;
  margin-top: -0.5em;
}
.ant-modal-content {
  font-family: 'OneNikeCurrency', 'TradeGothicW01-BoldCn20 675334', Helvetica, Arial, sans-serif;
}
.ant-btn {
  box-shadow: unset;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  box-shadow: unset;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background: linear-gradient(100deg, #1a1a1a, #118675 65%);
  box-shadow: 0 3px 5px rgba(77, 77, 77);
}
.ant-table.ant-table-small {
  font-size: 12px;
  border: 0px;
}
.ant-table table {
  text-align: left;
}
.ant-table-body {
  overflow: auto !important;
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 200px;
}
.ant-empty-image img {
  margin: auto;
}
.ant-table-thead {
  background: #118675;
  overflow: auto;
}
.ant-table-thead > tr > th {
  text-align: left;
  background: #f2f2f2 !important;
}
.ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #e8e8e8 !important;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  background: #f2f2f2;
}
.ant-table-fixed-header .ant-table-fixed-right .ant-table-header {
  background: #f2f2f2;
}
.ant-transfer {
  text-align: left;
}
.ant-input:focus,
.ant-input:hover {
  border-color: #efefef;
  box-shadow: 0 0 0 0 #efefef;
}
.ant-calendar-picker-input,
.ant-calendar-picker-input {
  border-color: #c9c9c9 !important;
  box-shadow: 0 0 0 0 #c9c9c9;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #efefef;
  outline: 0;
  box-shadow: 0 0 0 0 #efefef;
  border-right-width: 1px !important;
}
.ant-select-dropdown-menu-item:focus,
.ant-select-dropdown-menu-item-active,
.ant-select-dropdown-menu-item:hover {
  background-color: #efefef;
}
.nike-link {
  cursor: pointer;
  color: #949494;
}
.nike-link:hover {
  color: #949494;
  text-decoration: underline;
}
.breadcrumb-container {
  z-index: 1;
  background: white;
  padding: 16px 10px;
}
.shadow-box {
  box-shadow: 0px 5px 10px -5px rgba(161, 161, 161);
}
.shadow-box .ant-select-selection {
  background-color: #fae4e4;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.content-container {
  font-family: 'OneNikeCurrency', 'TradeGothicW01-BoldCn20 675334', Helvetica, Arial, sans-serif;
  background: #f0f0f0;
  margin: 10px;
}
.content-container .search-bar {
  background: white;
  border: 1px solid #7f7f7f;
  padding: 5px 0px;
  text-align: center;
}
.content-container .search-bar .search-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 15px;
  padding-right: 5px;
}
.content-container .search-bar .search-content {
  width: 100% !important;
  margin: 0;
}
.content-container .table-container {
  background: white;
  border: 1px solid #7f7f7f;
  padding: 10px;
  margin-top: 10px;
  height: calc(100vh - 190px);
  overflow: auto;
}
.content-container .table-container-csc {
  height: calc(100vh - 140px);
}
.content-container .table-container-expect {
  height: calc(100vh - 250px);
}
.content-container .table-container-expect-before {
  height: calc(100vh - 210px);
}
.content-container .table-container-actual {
  height: calc(100vh - 230px);
}
.content-container-inside {
  font-family: 'OneNikeCurrency', 'TradeGothicW01-BoldCn20 675334', Helvetica, Arial, sans-serif;
  background: white;
  border: 1px solid #7f7f7f;
  padding: 10px;
  margin-top: 0px;
  height: calc(100vh - 138px);
  overflow: auto;
}
.content-container-inside .title {
  margin: 20px 50px;
  padding: 10px 40px;
  font-size: larger;
  background: #f2f2f2;
}
.content-container-inside .form-container {
  margin: 20px 50px;
}
.content-container-inside .form-container .form-item {
  padding: 10px 0;
}
.content-container-inside .form-container .form-item .label {
  text-align: right;
  padding: 0 20px;
}
.content-container-inside .form-container .form-item .label .required {
  color: red;
}
.content-container-inside .form-container .form-item.actions button + button {
  margin-left: 80px;
}
.casi-modal .ant-btn:hover,
.casi-modal .ant-btn:focus,
.casi-modal .ant-btn:active {
  color: #118675;
  border-color: #118675;
}
.casi-modal .ant-btn.ant-btn-primary {
  background-color: #118675;
  border-color: #118675;
}
.casi-modal .ant-btn.ant-btn-primary:hover,
.casi-modal .ant-btn.ant-btn-primary:focus,
.casi-modal .ant-btn.ant-btn-primary:active {
  color: #118675;
  border-color: #118675;
  background-color: white;
}
.nike-link {
  cursor: pointer;
  color: #949494;
}
.nike-link:hover {
  color: #949494;
  text-decoration: underline;
}
.ant-select,
.ant-calendar-picker {
  width: 100% !important;
}
