@font-face {
  font-family: nike-glyphs;
  src: url('../fonts/nike-glyphs.eot');
  src: url('../fonts/nike-glyphs.eot?#iefix') format('eot'), url('../fonts/nike-glyphs.woff') format('woff'),
    url('../fonts/nike-glyphs.ttf') format('truetype'), url('../fonts/nike-glyphs.svg#nike-glyphs') format('svg');
}

[class*='g72'] {
  font-family: nike-glyphs;
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  vertical-align: top;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  text-decoration: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.g72-360:before {
  content: '\E601';
}

.g72-alert:before {
  content: '\E602';
}

.g72-arrow-fill-down:before {
  content: '\E603';
}

.g72-arrow-fill-left:before {
  content: '\E604';
}

.g72-arrow-fill-right:before {
  content: '\E605';
}

.g72-arrow-fill-up:before {
  content: '\E606';
}

.g72-arrow-thick-down:before {
  content: '\E607';
}

.g72-arrow-thick-left:before {
  content: '\E608';
}

.g72-arrow-thick-right:before {
  content: '\E609';
}

.g72-arrow-thick-up:before {
  content: '\E60A';
}

.g72-arrow-thin-left:before {
  content: '\E60B';
}

.g72-arrow-thin-right:before {
  content: '\E60C';
}

.g72-basketball:before {
  content: '\E60D';
}

.g72-cart:before {
  content: '\E60E';
}

.g72-chat:before {
  content: '\E60F';
}

.g72-check-circle:before {
  content: '\E610';
}

.g72-check:before {
  content: '\E611';
}

.g72-connected-apps:before {
  content: '\E612';
}

.g72-email:before {
  content: '\E613';
}

.g72-facebook:before {
  content: '\E614';
}

.g72-feed:before {
  content: '\E615';
}

.g72-filter:before {
  content: '\E616';
}

.g72-flag:before {
  content: '\E617';
}

.g72-friends:before {
  content: '\E618';
}

.g72-fuel:before {
  content: '\E619';
}

.g72-fuelband:before {
  content: '\E61A';
}

.g72-fullscreen:before {
  content: '\E61B';
}

.g72-gear:before {
  content: '\E61C';
}

.g72-gear2:before {
  content: '\E61D';
}

.g72-globe:before {
  content: '\E61E';
}

.g72-grid:before {
  content: '\E61F';
}

.g72-heart:before {
  content: '\E620';
}

.g72-help:before {
  content: '\E621';
}

.g72-home:before {
  content: '\E622';
}

.g72-hurley-icon:before {
  content: '\E623';
}

.g72-hurley:before {
  content: '\E624';
}

.g72-info:before {
  content: '\E625';
}

.g72-instagram:before {
  content: '\E626';
}

.g72-jordan:before {
  content: '\E627';
}

.g72-kinect:before {
  content: '\E628';
}

.g72-link:before {
  content: '\E629';
}

.g72-live-chat:before {
  content: '\E62A';
}

.g72-lock:before {
  content: '\E62B';
}

.g72-menu:before {
  content: '\E62C';
}

.g72-minus:before {
  content: '\E62D';
}

.g72-nano:before {
  content: '\E62E';
}

.g72-nikeid-circle:before {
  content: '\E62F';
}

.g72-nikeid:before {
  content: '\E630';
}

.g72-pause:before {
  content: '\E631';
}

.g72-payment-options:before {
  content: '\E632';
}

.g72-pin:before {
  content: '\E633';
}

.g72-pinterest:before {
  content: '\E634';
}

.g72-play:before {
  content: '\E635';
}

.g72-plus-large:before {
  content: '\E636';
}

.g72-plus:before {
  content: '\E637';
}

.g72-profile-share:before {
  content: '\E638';
}

.g72-profile:before {
  content: '\E639';
}

.g72-qzone:before {
  content: '\E63A';
}

.g72-rotate-left:before {
  content: '\E63B';
}

.g72-rotate-right:before {
  content: '\E63C';
}

.g72-running-gps:before {
  content: '\E63D';
}

.g72-search:before {
  content: '\E63E';
}

.g72-share:before {
  content: '\E63F';
}

.g72-snkrs:before {
  content: '\E640';
}

.g72-sportband:before {
  content: '\E641';
}

.g72-sportwatch:before {
  content: '\E642';
}

.g72-square:before {
  content: '\E643';
}

.g72-star:before {
  content: '\E644';
}

.g72-swoosh-circle:before {
  content: '\E645';
}

.g72-swoosh-plus:before {
  content: '\E646';
}

.g72-swoosh-word:before {
  content: '\E647';
}

.g72-swoosh:before {
  content: '\E648';
}

.g72-thumbs-down:before {
  content: '\E649';
}

.g72-thumbs-up:before {
  content: '\E64A';
}

.g72-training:before {
  content: '\E64B';
}

.g72-twitter:before {
  content: '\E64C';
}

.g72-volume-0:before {
  content: '\E64D';
}

.g72-volume-1:before {
  content: '\E64E';
}

.g72-volume-2:before {
  content: '\E64F';
}

.g72-volume-3:before {
  content: '\E650';
}

.g72-volume-mute:before {
  content: '\E651';
}

.g72-wechat:before {
  content: '\E652';
}

.g72-weibo:before {
  content: '\E653';
}

.g72-x-circle:before {
  content: '\E654';
}

.g72-x-small:before {
  content: '\E655';
}

.g72-x:before {
  content: '\E656';
}

.g72-youtube:before {
  content: '\E657';
}

.g72-zoom-in:before {
  content: '\E658';
}

.g72-zoom-out:before {
  content: '\E659';
}

.g72-clock:before {
  content: '\E65A';
}

.g72-converse:before {
  content: '\E65B';
}

.g72-nike-plus-snkrs:before {
  content: '\E65C';
}

.g72-edit:before {
  content: '\E65D';
}

.g72-arrow-down-outline:before {
  content: '\E65E';
}

.g72-arrow-down:before {
  content: '\E65F';
}

.g72-arrow-up-outline:before {
  content: '\E660';
}

.g72-arrow-up:before {
  content: '\E661';
}

.g72-bag:before {
  content: '\E662';
}

.g72-chat-2-outline:before {
  content: '\E663';
}

.g72-chat-2:before {
  content: '\E664';
}

.g72-flag-2-outline:before {
  content: '\E665';
}

.g72-flag-2:before {
  content: '\E666';
}

.g72-play-outline:before {
  content: '\E667';
}

.g72-reply:before {
  content: '\E668';
}

.g72-x-thick:before {
  content: '\E669';
}

.g72-zoom-outline:before {
  content: '\E66A';
}

.g72-clock-hands:before {
  content: '\E66B';
}

.g72-check-outline:before {
  content: '\E66C';
}

.g72-unlock:before {
  content: '\E66D';
}

.g72-triman-recycling:before {
  content: '\E66E';
}

.g72-location:before {
  content: '\E66F';
}

.g72-lock-keyhole:before {
  content: '\E670';
}
