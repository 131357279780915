.login-contain {
  width: 100%;
  height: 100%;
  position: fixed;
  background: url('../../assets/images/nike-bg.png') no-repeat fixed;
  background-size: cover;
}
.login-contain .login-view {
  margin-top: 50px;
}
