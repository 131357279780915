.total-content {
  margin-top: 10px;
  background: linear-gradient(100deg, white, #118675);
  border: 1px solid gray;
  height: 35px;
  line-height: 35px;
  width: 100%;
  overflow: hidden;
  padding: 0px 20px;
}
.total-content .total-number {
  width: 20px;
  margin-left: 10px;
  text-decoration: underline;
  color: #118675;
}
